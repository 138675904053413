.nav-links {
  list-style: none;
  display: flex;
  gap: 25px;
  margin: 0;
  padding: 0;
  color: #121212
}

.nav-links li {
  position: relative;
  border-radius: 15%;
  padding: 5px;
}

.nav-links li .NavLink {
  text-decoration: none;
  color: aliceblue;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-shadow: 1px 1px 2px black;

}

.nav-links li .NavLink:hover {
  color: aliceblue;
  transform: translateY(-3px);
}

.nav-links li .NavLink.active {
  color: rgb(255, 255, 255);
}

.nav-links li .NavLink::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #0077b5;
  left: 0;
  bottom: -6px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.nav-links li .NavLink:hover::after,
.nav-links li .NavLink.active::after {
  transform: scaleX(1);
}

.nav-links li .NavLink.active:hover::after {
  background-color: #302ad6;
  backdrop-filter: blur(70px);
}

.hamburger span {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin-bottom: 4px;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-links.mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: transparent;
    backdrop-filter: blur(0.75rem);
    padding: 1rem;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.4);
    border-radius: 1rem;
  }

  .nav-links.mobile li {
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-around;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    padding: 0 1.4rem;
  }
}
